import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/gis/',
    name: 'GIS',
    component: () => import('~/views/gis/Gis.vue'),
    meta: {
      title: 'ГИС ЖКХ',
    }
  },
  // {START} reports
  {
    path: '/gis/reports/account-export-errors/',
    name: 'GISAccountExportErrorsReport',
    component: () => import('~/views/gis/reports/GISAccountExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ЛС в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/bill-export-errors/',
    name: 'GISBillExportErrorsReport',
    component: () => import('~/views/gis/reports/GISBillExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ПД в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/individual-meter-export-error/',
    name: 'GISIndividualMeterExportErrorReport',
    component: () => import('~/views/gis/reports/GISIndividualMeterExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ИПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/individual-meter-value-export-error/',
    name: 'GISIndividualMeterValueExportErrorReport',
    component: () => import('~/views/gis/reports/GISIndividualMeterValueExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки показаний ИПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/house-meter-export-error/',
    name: 'GISHouseMeterExportErrorReport',
    component: () => import('~/views/gis/reports/GISHouseMeterExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки ОДПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/house-meter-value-export-error/',
    name: 'GISHouseMeterValueExportErrorReport',
    component: () => import('~/views/gis/reports/GISHouseMeterValueExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки показаний ОДПУ в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/payment-export-error-report/',
    name: 'GISPaymentExportErrorReport',
    component: () => import('~/views/gis/reports/GISPaymentExportErrorReport.vue'),
    meta: {
      title: 'Ошибки выгрузки оплат в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports/acknowledgment-export-errors-report/',
    name: 'GISAcknowledgmentExportErrorsReport',
    component: () => import('~/views/gis/reports/GISAcknowledgmentExportErrorsReport.vue'),
    meta: {
      title: 'Ошибки выгрузки квитирования в ГИС ЖКХ',
    }
  },
  // {END} reports

  // {START} debtors
  {
    path: '/gis/debtors/debt-request-statistics/',
    name: 'DebtRequestStatisticsReport',
    component: () => import('~/views/gis/debtors/DebtRequestStatisticsReport.vue'),
    meta: {
      title: 'Отчет по заявкам',
    }
  },
  {
    path: '/gis/debtors/debtors-report/',
    name: 'DebtorsReport',
    component: () => import('~/views/gis/debtors/DebtorsReport.vue'),
    meta: {
      title: 'Отчет по должникам',
    }
  },
  {
    path: '/gis/debtors/debtors-requests-report/',
    name: 'DebtorsRequestsReport',
    component: () => import('~/views/gis/debtors/DebtorsRequestsReport.vue'),
    meta: {
      title: 'Отчет по запросам из ОСЗН по должникам',
    }
  },
  // {END} debtors

  // {START} reports-statistic
  {
    path: '/gis/reports-statistic/upload-statistic/',
    name: 'GISUploadStatisticReport',
    component: () => import('~/views/gis/reports-statistic/GISUploadStatisticReport.vue'),
    meta: {
      title: 'Общая статистика выгрузки в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports-statistic/upload-statistic-by-house/',
    name: 'GISUploadStatisticByHouseReport',
    component: () => import('~/views/gis/reports-statistic/GISUploadStatisticByHouseReport.vue'),
    meta: {
      title: 'Статистика выгрузки в ГИС ЖКХ по домам',
    }
  },
  {
    path: '/gis/reports-statistic/export-common-statistics/',
    name: 'GISExportCommonStatisticsReport',
    component: () => import('~/views/gis/reports-statistic/GISExportCommonStatisticsReport.vue'),
    meta: {
      title: 'Паспорт выгрузки в ГИС ЖКХ',
    }
  },
  {
    path: '/gis/reports-statistic/upload-statistic-by-company-report/',
    name: 'GisUploadStatisticByCompanyReport',
    component: () => import('~/views/gis/reports-statistic/GisUploadStatisticByCompanyReport.vue'),
    meta: {
      title: 'Статистика по организациям',
    }
  },
  {
    path: '/gis/reports-statistic/withdrawn-bills-count-report/',
    name: 'WithdrawnBillsCountReport',
    component: () => import('~/views/gis/reports-statistic/WithdrawnBillsCountReport.vue'),
    meta: {
      title: 'Статистика по отозванным ПД',
    }
  },
  // {END} reports-statistic

  // {START} contract
  {
    path: '/gis/contract/contract-bills-report/',
    name: 'GISContractBillsReport',
    component: () => import('~/views/gis/contract/GISContractBillsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке счет-квитанций',
    }
  },
  {
    path: '/gis/contract/contract-idents-report/',
    name: 'GISContractIdentsReport',
    component: () => import('~/views/gis/contract/GISContractIdentsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке по лицевым счетам',
    }
  },
  {
    path: '/gis/contract/contract-meters-report/',
    name: 'GISContractMetersReport',
    component: () => import('~/views/gis/contract/GISContractMetersReport.vue'),
    meta: {
      title: 'Отчет о выгрузке по приборам учета',
    }
  },
  {
    path: '/gis/contract/contract-meters-values-report/',
    name: 'GISContractMetersValuesReport',
    component: () => import('~/views/gis/contract/GISContractMetersValuesReport.vue'),
    meta: {
      title: 'Отчет о передаче показаний приборов учета',
    }
  },
  {
    path: '/gis/contract/contract-requests-report/',
    name: 'GISContractRequestsReport',
    component: () => import('~/views/gis/contract/GISContractRequestsReport.vue'),
    meta: {
      title: 'Отчет об информации по запросам',
    }
  },
  {
    path: '/gis/contract/contracts-report/',
    name: 'GISContractsReport',
    component: () => import('~/views/gis/contract/GISContractsReport.vue'),
    meta: {
      title: 'Отчет о выгрузке договоров',
    }
  },
  // {END} contract
];

