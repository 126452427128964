import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/select-base',
    name: 'SelectBase',
    component: () => import('~/views/SelectBase.vue'),
    meta: {
      title: 'Выбор базы',
      layout: 'AuthLayout'
    }
  },
  {
    path: '/generators-categories/test',
    name: 'Generators.Test',
    component: () => import('~/views/TestGeneratorCategoriesLists.vue'),
    meta: {
      title: 'Тестовый генератор'
    }
  }
];

