import { setItemToLocalStorage, getItemFromLocalStorage } from '../utils/persistenceStorage';
/**
 * Добавление токена авторизации в localStorage
 *
 * @param {string} token  токен авторизации
 *
 */

export const setAccessToken = (token: string): void => {
  setItemToLocalStorage('accessToken', token);
};

/**
 * Получение токена авторизации из localStorage
 *
 * @returns {string}  token  токен авторизации
 *
 */

export const getAccessToken = (): string | null => {
  return getItemFromLocalStorage('accessToken');
};

/**
 * Удаление токена авторизации из localStorage
 *
 */

export const resetAccessToken = (): void => {
  localStorage.removeItem('accessToken');
};

/**
 * Удаление данных store из localStorage
 *
 */

export const resetAccountInfo = (): void => {
  localStorage.removeItem('vuex');
};