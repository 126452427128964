import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('~/views/auth/Login.vue'),
    meta: {
      title: 'Вход',
      layout: 'AuthLayout'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('~/views/auth/ResetPassword.vue'),
    meta: {
      title: 'Сброс пароля',
      layout: 'AuthLayout'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('~/views/auth/Registration.vue'),
    meta: {
      title: 'Регистрация',
      layout: 'AuthLayout'
    }
  }
];
