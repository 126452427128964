<template>
  <sm-modal
    v-model="showModal"
    :title="title"
    :show-close-icon="showCloseIcon"
    :canceble-backdrop="false"
    max-height="600px"
  >
    <template #default>
      <p class="sm-dialog__body">
        {{ text }}
      </p>
    </template>

    <template #footer>
      <sm-button
        v-if="showCancelButton"
        type="neutral"
        @click="handleCancel"
      >
        {{ cancelButtonText }}
      </sm-button>
      <sm-button
        v-if="showConfirmButton"
        @click="handleApply"
      >
        {{ confirmButtonText }}
      </sm-button>
    </template>
  </sm-modal>
</template>

<script lang="ts" setup>
// Modules
import { ref, toRefs, onMounted, nextTick } from 'vue';

// Components
import SmModal from '@/components/common/modal/SmModal.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

// Types
import { IDialogProps } from '@/types/components/modal';

// Props

const props = withDefaults(
  defineProps<IDialogProps>(),
  {
    title: '',
    showCloseIcon: false,
    text: '',
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: 'Отмена',
    confirmButtonText: 'Ок',
    cancebleBackdrop: false,
  }
);

const {
  title,
  showCloseIcon,
  text,
  showCancelButton,
  showConfirmButton,
  cancelButtonText,
  confirmButtonText,
} = toRefs(props);

// Emits

const emits = defineEmits<{
  (e: 'cancel' | 'confirm' | 'close'): void;
}>();

// Data

const showModal = ref<boolean>(false);

// Methods

const cancel = (): void => {
  showModal.value = false;

  setTimeout(() => {
    emits('close');
  }, 300);
}

// Handlers
const handleCancel = (): void => {
  emits('cancel');
  cancel();
}
const handleApply = (): void => {
  emits('confirm');
  cancel();
}

onMounted(() => {
  nextTick(() => {
    showModal.value = true;
  });
});
</script>