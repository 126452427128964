<template>
  <component :is="layoutComponent">
    <router-view />
  </component>
  <sm-notify-bar></sm-notify-bar>
</template>


<script lang="ts" setup>
// Vue moduls
import { computed } from 'vue';

// Composables
import { useRoute } from 'vue-router';
import { useBaseStore } from '~/stores/base'

// Components
import SmNotifyBar from '@core/components/common/notify/SmNotifyBar.vue';

// Utils TODO: Исправить название функции
import { setCSSStyles } from '@core/utils/setCSSStyles';

const { getBaseInfo } = useBaseStore();
const route = useRoute();

const layoutComponent = computed(() => route.meta.layoutComponent || null);

// Methods
const init = () => {
  getBaseInfo();
  setCSSStyles();
}

// Init
init();
</script>