import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/owners-offices/',
    name: 'OwnersOffices',
    component: () => import('~/views/owners-offices/OwnersOffices.vue'),
    meta: {
      title: 'Кабинеты собственников',
    }
  },
  // {START} payments
  {
    path: '/owners-offices/payments/act-of-service-provision-report/',
    name: 'ActOfServiceProvisionReport',
    component: () => import('~/views/owners-offices/payments/ActOfServiceProvisionReport.vue'),
    meta: {
      title: 'Акт об оказании услуг',
    }
  },
  // {END} payments

  // {START} reports
  {
    path: '/owners-offices/reports/account-info-report/',
    name: 'AccountInfoReport',
    component: () => import('~/views/owners-offices/reports/AccountInfoReport.vue'),
    meta: {
      title: 'Информация по лицевому счету',
    }
  },
  {
    path: '/owners-offices/reports/houses-info-report/',
    name: 'HousesInfoReport',
    component: () => import('~/views/owners-offices/reports/HousesInfoReport.vue'),
    meta: {
      title: 'Информация по домам',
    }
  },
  {
    path: '/owners-offices/reports/account-extract-report/',
    name: 'AccountExtractReport',
    component: () => import('~/views/owners-offices/reports/AccountExtractReport.vue'),
    meta: {
      title: 'Выписка из лицевого счета',
    }
  },
  {
    path: '/owners-offices/reports/house-extract-report/',
    name: 'HouseExtractReport',
    component: () => import('~/views/owners-offices/reports/HouseExtractReport.vue'),
    meta: {
      title: 'Выписка по дому',
    }
  },
    {
    path: '/owners-offices/reports/company-extract-report/',
    name: 'CompanyExtractReport',
    component: () => import('~/views/owners-offices/reports/CompanyExtractReport.vue'),
    meta: {
      title: 'Выписка по организации',
    }
  },
  {
    path: '/owners-offices/reports/accounting-and-payment-report/',
    name: 'AccountingAndPaymentReport',
    component: () => import('~/views/owners-offices/reports/AccountingAndPaymentReport.vue'),
    meta: {
      title: 'Проверка начислений и платежей (Услуги)',
    }
  },
  {
    path: '/owners-offices/reports/accounting-and-payment-by-ident-report/',
    name: 'AccountingAndPaymentByIdentReport',
    component: () => import('~/views/owners-offices/reports/AccountingAndPaymentByIdentReport.vue'),
    meta: {
      title: 'Проверка начислений и платежей (ЛС)',
    }
  },
  {
    path: '/owners-offices/reports/checking-reference-integrity-report/',
    name: 'CheckingReferenceIntegrityReport',
    component: () => import('~/views/owners-offices/reports/CheckingReferenceIntegrityReport.vue'),
    meta: {
      title: 'Отчет о нарушении связанности данных',
    }
  },
  {
    path: '/owners-offices/reports/meter-exist-values-report/',
    name: 'MeterExistValuesReport',
    component: () => import('~/views/owners-offices/reports/MeterExistValuesReport.vue'),
    meta: {
      title: 'Имеющиеся показания по приборам учета',
    }
  },
  {
    path: '/owners-offices/reports/checking-bills-report/',
    name: 'CheckingBillsReport',
    component: () => import('~/views/owners-offices/reports/CheckingBillsReport.vue'),
    meta: {
      title: 'Проверка квитанций',
    }
  },
  // {END} reports

  // {START} analytical reports
  {
    path: '/owners-offices/analytical-reports/mobile-users-analisis-report/',
    name: 'MobileUsersAnalisisReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileUsersAnalisisReport.vue'),
    meta: {
      title: 'Анализ использования мобильного приложения',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meter-values-report/',
    name: 'MeterValuesReport',
    component: () => import('~/views/owners-offices/analytical-reports/MeterValuesReport.vue'),
    meta: {
      title: 'Показания приборов учета',
    }
  },
  {
    path: '/owners-offices/analytical-reports/house-meter-values-received-report/',
    name: 'HouseMeterValuesReceivedReport',
    component: () => import('~/views/owners-offices/analytical-reports/HouseMeterValuesReceivedReport.vue'),
    meta: {
      title: 'Показания ОДПУ, переданные через МП',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meters-qr-codes-report/',
    name: 'MetersQrCodesReport',
    component: () => import('~/views/owners-offices/analytical-reports/MetersQrCodesReport.vue'),
    meta: {
      title: 'Отчет по QR-кодам приборов учета',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-announcement-report/',
    name: 'MobileAnnouncementReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileAnnouncementReport.vue'),
    meta: {
      title: 'Статистика просмотров уведомлений',
    }
  },
  {
    path: '/owners-offices/analytical-reports/payments-registry-report/',
    name: 'PaymentsRegistryReport',
    component: () => import('~/views/owners-offices/analytical-reports/PaymentsRegistryReport.vue'),
    meta: {
      title: 'Отчет по реестру оплат',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-users-in-dynamics-report/',
    name: 'MobileUsersInDynamicsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileUsersInDynamicsReport.vue'),
    meta: {
      title: 'Динамика роста пользователей мобильного приложения',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-detailed-statistics-report/',
    name: 'MobileDetailedStatisticsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileDetailedStatisticsReport.vue'),
    meta: {
      title: 'Детальная статистика',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-summary-statistics-report/',
    name: 'MobileSummaryStatisticsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileSummaryStatisticsReport.vue'),
    meta: {
      title: 'Сводная статистика',
    }
  },
  // {
  //   path: '/owners-offices/analytical-reports/sessions-log/',
  //   name: 'SessionsLog',
  //   component: () => import('~/views/owners-offices/analytical-reports/SessionsLog.vue'),
  //   meta: {
  //     title: 'Журнал авторизации',
  //   }
  // },
  {
    path: '/owners-offices/analytical-reports/callback-requests-report/',
    name: 'CallbackRequestsReport',
    component: () => import('~/views/owners-offices/analytical-reports/CallbackRequestsReport.vue'),
    meta: {
      title: 'Обращения с сайта',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-accounts-info-report/',
    name: 'MobileAccountsInfoReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobileAccountsInfoReport.vue'),
    meta: {
      title: 'Отчет по пользователям',
    }
  },
  {
    path: '/owners-offices/analytical-reports/idents-without-mobile-account-report/',
    name: 'IdentsWithoutMobileAccountReport',
    component: () => import('~/views/owners-offices/analytical-reports/IdentsWithoutMobileAccountReport.vue'),
    meta: {
      title: 'Отчет по лицевым счетам не привязанным к моб.аккаунтам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/mobile-payments-report/',
    name: 'MobilePaymentsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MobilePaymentsReport.vue'),
    meta: {
      title: 'Отчет по мобильным платежам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/payments-vs-paychecks-report/',
    name: 'PaymentsVsPaychecksReport',
    component: () => import('~/views/owners-offices/analytical-reports/PaymentsVsPaychecksReport.vue'),
    meta: {
      title: 'Отчет по чекам',
    }
  },
  {
    path: '/owners-offices/analytical-reports/loaded-receipts-report/',
    name: 'LoadedReceiptsReport',
    component: () => import('~/views/owners-offices/analytical-reports/LoadedReceiptsReport.vue'),
    meta: {
      title: 'Отчет по загруженным квитанциям',
    }
  },
  {
    path: '/owners-offices/analytical-reports/meter-values-robot-report/',
    name: 'MeterValuesRobotReport',
    component: () => import('~/views/owners-offices/analytical-reports/MeterValuesRobotReport.vue'),
    meta: {
      title: 'Отчет по роботу по приему ППУ',
    }
  },
  {
    path: '/owners-offices/analytical-reports/robot-for-meter-values-report/',
    name: 'RobotForMeterValuesReport',
    component: () => import('~/views/owners-offices/analytical-reports/RobotForMeterValuesReport.vue'),
    meta: {
      title: 'Отчет по роботу по приему показаний',
    }
  },
  {
    path: '/owners-offices/analytical-reports/robot-requests-report/',
    name: 'RobotRequestsReport',
    component: () => import('~/views/owners-offices/analytical-reports/RobotRequestsReport.vue'),
    meta: {
      title: 'Отчет по роботу-диспетчеру',
    }
  },
  // {
  //   path: '/owners-offices/analytical-reports/transitions-to-external-resources/',
  //   name: 'TransitionsToExternalResources',
  //   component: () => import('~/views/owners-offices/analytical-reports/TransitionsToExternalResources.vue'),
  //   meta: {
  //     title: 'Переходы на внешние ресурсы',
  //   }
  // },
  {
    path: '/owners-offices/analytical-reports/moe-accounts-report/',
    name: 'MoeAccountsReport',
    component: () => import('~/views/owners-offices/analytical-reports/MoeAccountsReport.vue'),
    meta: {
      title: 'Отчет по подключенным лиц. счетам МОЕ',
    }
  },
  // {END} analytical reports

  // {START} bonus-system
  {
    path: '/owners-offices/bonus-system/bonus-cash-flow-report/',
    name: 'BonusCashFlowReport',
    component: () => import('~/views/owners-offices/bonus-system/BonusCashFlowReport.vue'),
    meta: {
      title: 'Отчет по бонусам',
    }
  },
  // {END} bonus-system

  // {START} surveys
  {
    path: '/owners-offices/surveys/questions-report/',
    name: 'QuestionsReport',
    component: () => import('~/views/owners-offices/surveys/QuestionsReport.vue'),
    meta: {
      title: 'Отчет по опросам',
    }
  },
  {
    path: '/owners-offices/surveys/question-results-report/',
    name: 'QuestionResultsReport',
    component: () => import('~/views/owners-offices/surveys/QuestionResultsReport.vue'),
    meta: {
      title: 'Результаты опросов',
    }
  },
  // {END} surveys
  // {START} bonus-system
  {
    path: '/owners-offices/loading-from-excel/import-supply-contract-info/',
    name: 'OwnersOfficesImportSupplyContractInfo',
    component: () => import('~/views/owners-offices/loading-from-excel/OwnersOfficesImportSupplyContractInfo.vue'),
    meta: {
      title: 'Загрузка дат ДРСО',
    }
  },
  // {END} surveys

];

