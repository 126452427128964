// TODO: Позже посмотреть по ts
interface AppSettings {
  apiMng?: string;
  apiAero?: string;
  apiAds?: string;
}

declare global {
  interface Window {
    appSettings: AppSettings;
  }
}

export const apiMng: string =
  window.appSettings?.apiMng || process.env.VUE_APP_API_MNG;
export const apiAero: string =
  window.appSettings?.apiAero || process.env.VUE_APP_API_AERO;
export const apiAds: string =
  window.appSettings?.apiAds || process.env.VUE_APP_API_ADS;
