<template>
  <transition name="sm-notify-animate">
    <div
      v-show="showNotify"
      class="sm-notify"
    >    
      <sm-icon
        :name="iconName"
        :size="24"
        class="sm-notify__icon"
        :class="iconClassList"
      />
      <div :class="contentClassList">
        <header
          class="sm-notify__header"
        >
          <h3
            v-if="title"
            class="sm-notify__title"
          >{{ title }}</h3>        
          <sm-icon
            name="Cancel"
            :size="16"
            class="sm-notify__close"
            @click="close"
          />
        </header>
        <p class="sm-notify__text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
// Modules
import { ref, onMounted, toRefs, computed } from "vue";
// Types
import { INotifyProps } from "@/types/components/notify";
import { Class } from "@/types/common";
// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

const props = withDefaults(defineProps<INotifyProps>(), {
  type: 'neutral',
  title: '',
  delay: 6000,
});

const {
  type,
  title,
  text,
  delay,
} = toRefs(props);

const showNotify = ref<boolean>(false);

const iconNames:Record<string, string> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
  info: 'Info',
};

const iconName = computed((): string => iconNames[type.value] || iconNames.info);

const iconClassList = computed((): Class => {
  return `sm-notify__icon--${type.value}`
});

const contentClassList = computed((): Class => {
  return [
    'sm-notify__content',
    !title.value ? 'sm-notify__content--inline' : '',
  ]
  .filter(item => item);
});

const close = (): void => {
  showNotify.value = false;
}

onMounted(() => {
  showNotify.value = true;

  setTimeout(() => {
    close();
  }, delay.value);
});
</script>

<style lang="scss">
.sm-notify {
  display: flex;
  align-items: flex-start;

  width: 370px;
  padding: var(--padding);
  margin-bottom: var(--margin-s);
  gap: var(--gap-s);

  border-radius: var(--stroke);

  background-color: var(--Background);
  box-shadow: 0px 4px 8px 0px #3C507714;
  box-shadow: 0px 8px 16px 0px #152D5B1C;
}

$types: (
  'success': 'Success',
  'warning': 'Warning',
  'error': 'Error',
  'info': 'Info',
);

@each $key, $type in $types {
  .sm-notify__icon--#{$key} {
    color: var(--#{'Icon' + $type});
  }
}

.sm-notify__content {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.sm-notify__content--inline {
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;

  gap: var(--gap);
}

.sm-notify__content--inline .sm-notify__header {
  width: fit-content;
}

.sm-notify__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  gap: var(--gap-s);
  margin-bottom: var(--padding-s);
}

.sm-notify__title {
  @include main;

  flex-shrink: 1;

  margin: 0;
}

.sm-notify__close {
  flex-shrink: 0;

  margin-left: auto;

  cursor: pointer;

  color: var(--Icon);
}

.sm-notify__icon {
  flex-shrink: 0;
}

.sm-notify__text {
  @include mini;
  margin: 0;
}

// Animation
.sm-notify-animate-enter-from,
.sm-notify-animate-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.sm-notify-animate-enter-active,
.sm-notify-animate-leave-active {
  @include transition(('transform', 'opacity'));
}
</style>