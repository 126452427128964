import { h, render, App } from "vue";
import SmDialog from "@/components/common/modal/SmDialog.vue";
import { IDialogProps } from "../types/components/modal";

type IDialogConponent = PromiseConstructor;

export default {
  install(app: App) {
    const dialog = async (props: IDialogProps): Promise<IDialogConponent> => {
      let node: Node | null = null;

      const container = document.createElement('div');
      document.body.appendChild(container);

      const response: PromiseConstructor = await new Promise((resolve): void => {
        const destroy = (): void => {
          render(null, container);
          if (node) {
            document.body.removeChild(node);
          }
        }

        const component = h(SmDialog, {
          ...props,
          ...{
            ...resolve,
            onClose: () => destroy()
          }
        });

        render(component, container);
        node = container.firstElementChild;
      });

      return response;
    }

    app.provide('dialog', dialog);
  }
}